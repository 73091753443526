<template>
  <div class="pt-4 pb-5">
    <h1 class="dark-text fz-28 font-weight-semi-bold mb-1 d-none d-md-block">Activity Log</h1>
    <h1 class="dark-text fz-25 font-weight-semi-bold mb-1 d-md-none px-5 mb-4">Activity Log</h1>

    <div class="px-5 px-md-0 mb-1 mb-md-0 d-inline-flex log-select align-end">
      <SelectComponent class="select-component" :label="true" :value="logTypes" @selectedValue="setLogType" />
      <div :class="{ 'ml-2': isDesktop }" class="px-0 d-flex align-center mb-3">
        <div style="width: 130px;">
          <span>From:</span>
          <date-picker-solo :value="formatPickerDate(filter.startDate)" @changeDate="changeStartDate"/>
        </div>
        <div class="ml-4" style="width: 130px;">
          <span>To:</span>
          <date-picker-solo :value="formatPickerDate(filter.endDate)" @changeDate="changeEndDate"/>
        </div>
      </div>
    </div>
    <div class="px-5 px-md-0 mb-1 mb-md-0 d-inline-flex" :class="{ 'ml-2': isDesktop }">
    </div>
    <div>
      <v-card
        elevation="0"
        class="transparent pa-0 pt-2 v-card--active-log"
        :loading="loading"
        style="min-height:4px"
      >
        <v-list
          class="transparent py-0 bg-white-mobile"
          elevation="0"
          tile
        >
          <template v-for="(date, idx) in Object.keys(callLogList)">
            <div
              :key="idx + 'group'"
              class="fz-12 text-uppercase font-weight-bold py-4 px-md-9 px-14"
              style="color: #637381;"
            >
              {{ convertDate(date) }}
            </div>

            <template v-for="(item, itemIndex) in callLogList[date]">
              <v-list-item
                :key="itemIndex + 'item' + itemIndex + idx"
                class="pa-0 fz-14 d-flex px-5 px-md-0"
              >

                <div class="ml-7 d-flex align-center flex-md-nowrap flex-wrap pr-10">
                  <div class="mr-4">
                    <span v-if="item.direction === 'out'" class="mr-1">{{ item.ani }}</span>
                    <router-link
                      v-else
                      class="blue--text text-decoration-none mr-1"
                      :to="getRedirectLink(item, true)"
                    >
                      <span v-if="!item.caller_first_name && !item.caller_last_name">{{ item.ani }}</span>
                      <span v-else>{{item.caller_first_name}} {{item.caller_last_name}}</span>
                    </router-link>

                    <span>{{logs[item.type]}}</span>

                    <span v-if="item.direction === 'in'" class="ml-1">{{ item.dnis }}</span>
                    <router-link
                      v-else
                      class="blue--text text-decoration-none ml-1"
                      :to="getRedirectLink(item, false)"
                    >
                      <span v-if="!item.callee_first_name && !item.callee_last_name">{{ item.dnis }}</span>
                      <span v-else>{{item.callee_first_name}} {{item.callee_last_name}}</span>
                    </router-link>
                  </div>

                  <span
                    v-if="item.type !== 'sms'"
                    class="text-no-wrap mr-2 d-none d-md-block"
                    style="color: #3E4E57;"
                  >
                    duration: {{ item.duration ? item.duration : 0 }}s
                  </span>
                </div>

                <div class="ml-auto text-lowercase" style="color: #637381; font-variant-numeric: tabular-nums">
                  {{ formatDate(item.created_on, 'hh:mm A') }}
                </div>
              </v-list-item>
            </template>
          </template>
        </v-list>
      </v-card>
      <v-btn
        v-if="Object.keys(logList).length > countByDefault"
        text
        link
        class="text-none fill-width"
        color="link"
        height="50px"
        @click="logExpand = !logExpand"
      >
        Show {{ logExpand ? 'less' : 'all' }}
        <v-icon color="regular-text" :class="{ 'button__icon--active': logExpand }">expand_more</v-icon>
      </v-btn>
    </div>
    <div v-if="!Object.keys(logList).length && !loading" class="text-center text-h3">
      <span>No logs</span>
    </div>
  </div>
</template>

<script>
import SelectComponent from '@/components/SelectComponent'
import DatePickerSolo from '@/components/DatePickerSolo'
import { mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'CallLog',
  components: {
    SelectComponent,
    DatePickerSolo
  },
  data: () => ({
    logTypes: [
      {
        label: 'All log',
        value: ''
      }
    ],
    logType: '',
    logExpand: false,
    logList: {},
    loading: false,
    countByDefault: 3,
    filter: {
      orderBy: 'created_on',
      orderType: 'desc',
      startDate: moment(Date.now()).format('YYYY/MM/DD'),
      endDate: moment(Date.now()).format('YYYY/MM/DD')
    },
    logs: {
      call: 'called to',
      sms: 'send SMS to',
      voicemail: 'send voicemail to'
    },
    timeOptions: {
      start: { hour: 0, minute: 0, second: 0, millisecond: 0 },
      end: { hour: 23, minute: 59, second: 59, millisecond: 59 },
      format: 'YYYY-MM-DD HH:mm:ss'
    }
  }),
  computed: {
    isDesktop () { return this.$vuetify.breakpoint.mdAndUp },
    callLogList () {
      return this.logExpand
        ? this.logList
        : Object.keys(this.logList)
          .slice(0, this.countByDefault)
          .reduce((a, b) => ({ ...a, [b]: this.logList[b] }), {})
    }
  },
  created () {
    this.membersList()
    this.getLogs()
  },
  methods: {
    ...mapActions(['getMembers']),
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    },
    convertDate (date) {
      const today = new Date().toISOString().split('T')[0]
      let formatDate
      if (today === date) {
        formatDate = 'Today'
      } else {
        formatDate = new Date(date).toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric'
        })
      }

      return formatDate
    },
    changeStartDate (value) {
      if (new Date(value) > new Date(this.filter.endDate)) {
        this.filter.startDate = `${this.filter.endDate}`
        this.filter.endDate = moment(value || Date.now()).set(this.timeOptions.start).format(this.timeOptions.format)
      } else this.filter.startDate = moment(value || Date.now()).set(this.timeOptions.start).format(this.timeOptions.format)
      this.getLogs()
    },
    changeEndDate (value) {
      if (new Date(value) < new Date(this.filter.startDate)) {
        this.filter.endDate = `${this.filter.startDate}`
        this.filter.startDate = moment(value || Date.now()).set(this.timeOptions.end).format(this.timeOptions.format)
      } else this.filter.endDate = moment(value || Date.now()).set(this.timeOptions.end).format(this.timeOptions.format)
      this.getLogs()
    },
    async getLogs () {
      try {
        this.loading = true

        const filter = this.filter
        if (this.logType) {
          filter.user_uuid = this.logType
        } else {
          delete filter.user_uuid
        }

        const { data } = await this.$store.dispatch('getCallLogs', filter)

        this.logList = data.payload
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async membersList () {
      const { data } = await this.getMembers()
      const logTypes = [
        {
          label: 'All log',
          value: ''
        }
      ]
      data.members.forEach(item => {
        logTypes.push({
          label: `${item.user_first_name} ${item.user_last_name}`,
          value: item.user_uuid
        })
      })
      this.logTypes = logTypes
    },
    setLogType (e) {
      this.logType = e
      this.getLogs()
    },
    getRedirectLink (item, trigger) {
      if (
        (trigger && item.direction === 'in' && (item.caller_first_name || item.caller_last_name)) ||
        (!trigger && item.direction === 'out' && (item.callee_first_name || item.callee_last_name))
      ) return { name: 'UserProfile', params: { type: 'contactDetails', id: item.contact_uuid } }
      else if (
        trigger && item.direction === 'in' && !item.caller_first_name && !item.caller_last_name
      ) return { name: 'NewContact', params: { number: item.ani } }
      else if (
        !trigger && item.direction === 'out' && !item.callee_first_name && !item.callee_last_name
      ) return { name: 'NewContact', params: { number: item.dnis } }
      else return { name: 'CallLog' }
    }
  }
}
</script>

<style scoped lang="scss">
  .bg-white-mobile {
    @include media-breakpoint-up(md) {
      background-color: transparent !important;
    }
    background-color: white !important;
  }
  .v-card--active-log ::v-deep .v-card__progress {
    min-height: 4px;
  }

  .log-select {
    display: flex;
    gap: 20px;
  }

  .select-component {
    min-width: 130px;
  }

  @media screen and (max-width: 680px) {
    .log-select .select-component {
      width: 130px;
    }
  }

  @media screen and (max-width: 470px) {
    .log-select {
      flex-direction: column;
      align-items: flex-start;
      max-width: 100vw;
      .select-component {
        width: 100%;
      }
      ::v-deep .v-input__slot {
        height: 48px;
      }
    }
  }
</style>
