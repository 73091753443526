<template>
    <callflow-step
      :hang-up-option="hangUpOption"
      :path="path"
      icon="dialpad"
      step-name="Menu"
      @insertStepComponent="insertStep"
      @deleteStepIndex="$emit('deleteStepIndex')"
    >
      <CallflowVoiceMessage :path="path" />
      <v-row class="my-0">

        <v-col
          cols="12"
          md="12"
          class="px-3"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="px-7 py-0"
            >
              <div class="fz-12 text-uppercase font-weight-semi-bold">If the caller</div>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="alert-msg-parent pb-0 mb-2" style="position: relative"
            >
              <v-alert
                v-for="(dtmf, index) in menuDtmf"
                :key="index"
                class="alert-msg mb-2 success-alert"
              >
                <v-row class="align-center">
                  <v-col
                    cols="12"
                    lg="3"
                    md="4"
                  >
                    <v-select
                      :value="dtmf.name"
                      :items="computedPressList"
                      item-text="label"
                      item-value="value"
                      item-disabled="disabled"
                      menu-props="offsetY"
                      solo
                      outlined
                      hide-details
                      style="height: 38px; min-width: 115px"
                      @change="changeDtmfName($event, index)"
                    >
                      <template v-slot:append>
                        <v-icon size="15px">unfold_more</v-icon>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    md="6"
                    class="px-1"
                  >
                    <v-select
                      :value="dtmf.type"
                      :items="stepList"
                      item-text="label"
                      item-value="value"
                      item-disabled="disabled"
                      menu-props="offsetY"
                      solo
                      outlined
                      hide-details
                      style="height: 38px; min-width: 115px"
                      @change="changeDtmfType($event, index)"
                    >
                      <template v-slot:append>
                        <v-icon size="15px">unfold_more</v-icon>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col
                    cols="12"
                    lg="3"
                    md="2"
                    class="text-right"
                  >
                    <v-btn
                      text
                      icon
                      @click="removeMenuDtmf(index)"
                    >
                      <v-icon
                        color="#108043"
                      >remove_circle
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <callflow-toggle-action
                  :trigger="isActionVisible([index])"
                  :id="`start_${path[0]}_menu_${index}`"
                  @toggle="toggleActionVisibility([index])" />
              </v-alert>
            </v-col>

            <v-col
              cols="12"
              md="12"
              class="pt-0"
            >
              <v-btn
                text
                class="text-none d-flex align-center px-2 ml-4"
                height="36px"
                @click="addMenuDtmf"
              >
                <v-icon size="24px" color="#919EAB">add</v-icon>
                Add number
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </callflow-step>
</template>

<script>
import CallflowVoiceMessage from './CallflowVoiceMessage'
import CallflowStep from './CallflowStep'
import CallflowToggleAction from './CallflowToggleAction'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'CallflowMenu',
  components: {
    CallflowStep,
    CallflowVoiceMessage,
    CallflowToggleAction
  },
  props: {
    hangUpOption: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    },
    path: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    pressesList: [
      {
        label: 'Presses 1',
        value: '1'
      },
      {
        label: 'Presses 2',
        value: '2'
      },
      {
        label: 'Presses 3',
        value: '3'
      },
      {
        label: 'Presses 4',
        value: '4'
      },
      {
        label: 'Presses 5',
        value: '5'
      },
      {
        label: 'Presses 6',
        value: '6'
      },
      {
        label: 'Presses 7',
        value: '7'
      },
      {
        label: 'Presses 8',
        value: '8'
      },
      {
        label: 'Presses 9',
        value: '9'
      },
      {
        label: 'Presses 0',
        value: '0'
      },
      {
        label: 'Presses #',
        value: '#'
      },
      {
        label: 'Presses *',
        value: '*'
      }
    ],
    stepListData: [
      {
        value: 0,
        label: 'Greeting'
      },
      {
        value: 1,
        label: 'Dial'
      },
      {
        value: 2,
        label: 'Simulcall'
      },
      /* {
        value: 6,
        label: 'Tag'
      }, */
      {
        value: 7,
        label: 'Voicemail'
      },
      {
        value: 8,
        label: 'HangUp'
      }
    ]
  }),
  computed: {
    ...mapGetters('callflow', ['getFromPath', 'steps', 'hasHangUp']),
    menuDtmf () {
      return this.getFromPath(
        this.path.concat('payload', 'menuDtmf')
      )
    },
    computedPressList () {
      return this.pressesList.map(item => ({
        ...item,
        disabled: this.menuDtmf.findIndex(e => e.name === item.value) > -1
      }))
    },
    stepList () {
      return this.stepListData.map(item => ({
        ...item,
        disabled: item.value === 8 && this.hasHangUp
      }))
    }
  },
  methods: {
    ...mapMutations('callflow', [
      'setStepValue',
      'pushToStep',
      'removeFromStep'
    ]),
    ...mapActions('callflow', ['addStep', 'calculateLines']),
    removeMenuDtmf (index) {
      this.removeFromStep({
        path: this.path.concat('payload', 'menuDtmf', index)
      })
      this.removeFromStep({
        path: this.path.concat('actions', 'branches', index)
      })
      this.removeFromStep({
        path: this.path.concat('children', index)
      })

      const path = [this.path[this.path.length - 1] + 1 + index]
      this.removeFromStep({
        path
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    addMenuDtmf () {
      const name = this.pressesList.find(item => this.menuDtmf.findIndex(e => e.name === item.value) === -1).value
      this.pushToStep({
        path: this.path.concat('payload', 'menuDtmf'),
        value: { name, type: 0 }
      })
      const id = Date.now()
      this.pushToStep({
        path: this.path.concat('actions', 'branches'),
        value: { name, type: 0, id }
      })
      this._addStep(id)
      this.pushToStep({ path: this.path.concat('children'), value: id })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    _addStep (id, index = -1, stepListIndex = 0, isReplacement = false) {
      const parent = this.getFromPath(this.path)

      const name = 'Callflow' + this.stepList[stepListIndex].label

      const path = [this.path[this.path.length - 1] + (isReplacement ? 0 : 1) + (isReplacement ? index + 1 : parent.children.length)]

      if (index < 0) index = parent.children.length
      const branch = this.getFromPath(this.path.concat('payload', 'menuDtmf', index, 'name'))
      const message = { type: 'menu', branch }

      this.addStep({ path, name, parent: parent.id, id, message, isReplacement })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    changeDtmfName (value, index) {
      this.setStepValue({
        path: this.path.concat('payload', 'menuDtmf', index, 'name'),
        value
      })
      this.setStepValue({
        path: this.path.concat('actions', 'branches', index, 'name'),
        value
      })
      const path = [this.path[this.path.length - 1] + 1 + index]
      this.setStepValue({
        path: path.concat('message', 'branch'),
        value
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    changeDtmfType (value, index) {
      this.setStepValue({
        path: this.path.concat('payload', 'menuDtmf', index, 'type'),
        value
      })
      const id = Date.now()
      const name = this.getFromPath(this.path.concat('payload', 'menuDtmf', index, 'name'))
      this.setStepValue({
        path: this.path.concat('actions', 'branches', index),
        value: { name, type: value, id }
      })
      const stepListIndex = this.stepList.findIndex(e => e.value === value)
      this._addStep(id, index, stepListIndex, true)
      this.setStepValue({
        path: this.path.concat('children', index),
        value: id
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    },
    insertStep (component) {
      this.$emit('insertStepComponent', { name: component.name, index: this.index })
    },
    isActionVisible (path) {
      const action = this.getFromPath(this.path.concat('actions', 'branches', path))
      const children = this.steps.find(e => e.id === action.id)
      return !children || children.isVisible
    },
    toggleActionVisibility (path) {
      const parent = this.getFromPath(this.path)
      const action = this.getFromPath(this.path.concat('actions', 'branches', path))
      const childIndex = parent.children.findIndex(e => e === action.id)
      const childPath = [this.path[this.path.length - 1] + childIndex + 1]
      const child = this.getFromPath(childPath)
      this.setStepValue({
        path: childPath.concat('isVisible'),
        value: !child.isVisible
      })
      setTimeout(() => {
        this.calculateLines()
      }, 500)
    }
  }
}
</script>
<style scoped lang="scss">
  ::v-deep .hide-search input {
    z-index: -1 !important;
  }
  .alert-msg {
    &:before {
      content: '';
      border: 1px solid #DFE3E8;
      position: absolute;
      left: -39px;
      top: 32px;
      width: 36px;
      height: 1px;
      border-right: 0 !important;
    }
  }
  .msg {
    &:before {
      content: '';
      border: 1px solid #DFE3E8;
      position: absolute;
      left: -36px;
      top: 9px;
      width: 30px;
      height: 1px;
      border-right: 0 !important;
    }
  }
</style>
